import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// CONTEXTS
import { SelectorProvider } from "./contexts";

// PAGES
import ProductSelector from "./pages/productSelector";
import ProductList from "./pages/productList";
import PdfPreview from "./pages/pdfPreview";

function App() {
  return (
    <Router>
      <SelectorProvider>
        <Switch>
          <Route exact path="/">
            <ProductSelector />
          </Route>
          <Route exact path="/product-list">
            <ProductList />
          </Route>
          <Route exact path="/preview">
            <PdfPreview />
          </Route>
        </Switch>
      </SelectorProvider>
    </Router>
  );
}

export default App;
