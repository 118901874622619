import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "@rebass/grid";
import { PDFViewer } from "@react-pdf/renderer";

import { SelectorContext } from "../../contexts";
import PDFDoc from "../../pdfDocs";

import * as Styled from "./styles";

const PdfPreview = () => {
  const history = useHistory();
  const selectorContext = useContext(SelectorContext);

  const numSelections = Object.keys(selectorContext.selectedProductMap).length;

  return (
    <>
      <Styled.Header
        paddingX={5}
        justifyContent="space-between"
        className="print-hide-print-elem"
      >
        <Flex>
          <Styled.BackButton onClick={() => history.push("/")}>
            Back
          </Styled.BackButton>
        </Flex>
      </Styled.Header>
      <Flex marginTop="80px" flex={1} width={1} className="print-elem">
        {numSelections ? (
          <PDFViewer style={{ width: "100%", height: "calc(100vh - 80px)" }}>
            <PDFDoc
              title={selectorContext.title}
              salesRep={selectorContext.salesRep}
              productsSplitByVendor={
                selectorContext.selectedProductsSplitByVendor
              }
            />
          </PDFViewer>
        ) : (
          <p>No products selected</p>
        )}
      </Flex>
    </>
  );
};

export default PdfPreview;
