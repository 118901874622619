import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// logos
import craft360Logo from "../../assets/craft360.png";
import lcLogo from "../../assets/lc-logo.png";
import dejadoLogo from "../../assets/dejado-logo.png";
import highparkLogo from "../../assets/highpark.png";
import lsdcLogo from "../../assets/lsdc-logo.png";

import ListItem from "./components/listItem";

const ProductSelector = ({
  title = "",
  salesRep = {},
  productsSplitByVendor = {},
}) => {
  return (
    <Document>
      <Page size="LETTER" orientation="landscape" style={pdfStyles.page}>
        <View style={headerStyles.pageHeader}>
          <View style={headerStyles.goldStrip} />
          <View style={headerStyles.logoContainers}>
            <View style={headerStyles.craft360Container}>
              <Image src={craft360Logo} style={headerStyles.craft360Image} />
            </View>
            <View style={headerStyles.rightContentContainer}>
              <View style={headerStyles.logosContainer}>
                <Image src={lcLogo} style={headerStyles.lcLogoImage} />
                <Image src={dejadoLogo} style={headerStyles.dejadoLogoImage} />
                <Image src={lsdcLogo} style={headerStyles.lsdcLogoImage} />
                <Image src={highparkLogo} style={headerStyles.highParkImage} />
              </View>
              {salesRep && (
                <View style={headerStyles.salesRepInfoContainer}>
                  <Text style={headerStyles.salesRepInfoText}>
                    {salesRep.name}
                  </Text>
                  <Text style={headerStyles.salesRepInfoText}>|</Text>
                  <Text style={headerStyles.salesRepInfoText}>
                    {salesRep.email}
                  </Text>
                  <Text style={headerStyles.salesRepInfoText}>|</Text>
                  <Text style={headerStyles.salesRepInfoText}>
                    {salesRep.phoneNumber}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
        <View style={listStyles.container}>
          <Text style={listStyles.title}>{title || ""}</Text>
          {Object.entries(productsSplitByVendor).map(([vendor, products]) => {
            return (
              <View style={listStyles.listContainer} key={vendor}>
                {products.map((product) => {
                  return (
                    <ListItem
                      key={product?.id}
                      className="product-list-item"
                      product={product}
                    />
                  );
                })}
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default ProductSelector;

const listStyles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginHorizontal: "15px",
    marginBottom: "0px",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "15px",
    marginVertical: "10px",
  },
  listContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
});

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    width: "100%",
    margin: 0,
    padding: 0,
  },
});

// Create styles
const headerStyles = StyleSheet.create({
  pageHeader: {
    width: "100%",
    height: "150px",
    backgroundColor: "#faf9f6",
    display: "flex",
    flexDirection: "column",
  },
  goldStrip: {
    width: "100%",
    height: "10px",
    backgroundColor: "#b68f31",
  },
  logoContainers: {
    padding: "3px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
  rightContentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  craft360Container: {
    marginLeft: "10px",
    display: "flex",
    alignSelf: "center",
  },
  craft360Image: {
    width: "auto",
    height: "90px",
  },
  logosContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid lightgrey",
  },
  lcLogoImage: {
    height: "55px",
    width: "auto",
    margin: "8px",
  },
  dejadoLogoImage: {
    height: "40px",
    width: "auto",
    margin: "8px",
  },
  lsdcLogoImage: {
    height: "55px",
    width: "auto",
    margin: "8px",
  },
  highParkImage: {
    height: "20px",
    width: "auto",
    margin: "8px",
  },
  salesRepInfoContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "8px",
    flexDirection: "row",
  },
  salesRepInfoText: {
    fontWeight: "bold",
    fontSize: "10px",
    textTransform: "uppercase",
    marginHorizontal: "3px",
    marginTop: "5px",
  },
});
