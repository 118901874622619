import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import { styled } from "@material-ui/core";

const labelMap = {
  style: "Style",
  abv: "ABV",
  ibu: "IBU",
  calories: "Calories",
  sugar: "Sugar",
  retail_sku: "Retail SKU",
  retail_price: "Retail Price",
  retail_availability: "Retail Availability",
  article_no: "Article #",
  format: "Format",
  licensee_price: "Licensee Price (excl. HST & Deposit)",
  licensee_availability: "Licensee Availability",
  retail_dc: "Retail DC",
};

const ListItem = ({ product, ...rest }) => {
  const renderMetafield = (field) => {
    if (!product?.metafields?.[field]) return null;

    return (
      <View style={styled.fieldLabelContnainer}>
        <Text style={styles.key}>
          {labelMap[field]}: {product.metafields[field]}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.container} wrap={false} {...rest}>
      <View style={styles.imageContainer}>
        <Image style={styles.productImage} src={product.smallImageSrc} />
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.productTitle}>{product.title}</Text>
          <Text style={styles.vendorName}>{product.vendor}</Text>
        </View>
        <View style={styles.descriptionContainer}>
          <Text style={styles.descriptionText}>{product.description}</Text>
        </View>
        <View style={styles.metaFeildContainer}>
          {renderMetafield("style")}
          {renderMetafield("abv")}
          {renderMetafield("ibu")}
          {renderMetafield("calories")}
          {renderMetafield("sugar")}
          <View style={styles.br} />
          {renderMetafield("retail_sku")}
          {renderMetafield("retail_price")}
          {renderMetafield("retail_availability")}
          {renderMetafield("retail_dc")}
          <View style={styles.br} />
          {renderMetafield("article_no")}
          <View style={styles.br} />
          {renderMetafield("format")}
          {renderMetafield("licensee_price")}
          {renderMetafield("licensee_availability")}
        </View>
      </View>
    </View>
  );
};

export default ListItem;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "95%",
    marginVertical: "5px",
  },
  imageContainer: {
    minWidth: "360px",
    maxHeight: "100%",
    padding: "0px",
    margin: "0px",
    backgroundColor: "#f2f2f2",
  },
  productImage: {
    height: "auto",
    maxWidth: "380px",
    padding: "0px",
    margin: "0px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: "12px",
    width: "90%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
  },
  productTitle: {
    padding: "0px",
    marginVertical: "3px",
    color: "black",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "14px",
  },
  vendorName: {
    padding: "0px",
    marginVertical: "3px",
    color: "grey",
    textTransform: "uppercase",
    fontWeight: "400",
    fontSize: "13px",
    marginLeft: "5px",
    alignSelf: "center",
  },
  descriptionContainer: {
    width: "95%",
    marginVertical: "10px",
  },
  descriptionText: {
    marginVertical: "2px",
    fontSize: "12px",
  },
  metaFeildContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginTop: "5px",
  },
  fieldLabelContnainer: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid red",
  },
  key: {
    padding: "0px",
    margin: "1px 0px",
    fontWeight: "bold",
    fontSize: "12px",
  },
  br: {
    marginVertical: "5px",
  },
});
