import React, { useContext } from "react";
import { SelectorContext } from "../../contexts";
import ProductCard from "./components/productCard";
import { useHistory, useLocation } from "react-router-dom";
import { Flex } from "@rebass/grid";

import salesReps from "../../salesReps";

import * as Styled from "./styles";

const ProductSelector = () => {
  const history = useHistory();
  const selectorContext = useContext(SelectorContext);
  const { search } = useLocation();

  return (
    <>
      <Styled.Header justifyContent="space-between" paddingX={5}>
        <Styled.DesktopOnlyContainer>
          <Flex marginRight={3}>
            <Styled.Button
              variant="contained"
              onClick={selectorContext.selectAll}
            >
              Select All
            </Styled.Button>
          </Flex>

          <Styled.Button
            variant="contained"
            onClick={selectorContext.clearSelections}
          >
            Deselect All
          </Styled.Button>
        </Styled.DesktopOnlyContainer>
        <Styled.InputsContainer>
          <Styled.Dropdown
            options={salesReps}
            onChange={(e, rep) => selectorContext.setSalesRep(rep)}
            getOptionLabel={(option) => option.name}
            autoSelect
            value={selectorContext.salesRep}
            renderInput={(params) => (
              <Styled.Input {...params} label="Sales Reps" />
            )}
          />

          <Styled.Input
            label="Title"
            onChange={(event) => selectorContext.setTitle(event?.target?.value)}
            value={selectorContext.title}
          />
          <Styled.Button
            variant="contained"
            onClick={() => {
              history.push(`/product-list${search}`);
            }}
          >
            Generate Product Sheet
          </Styled.Button>
        </Styled.InputsContainer>
      </Styled.Header>
      {selectorContext.loading ? (
        <Styled.Loader />
      ) : (
        <Styled.ContentContainer flexDirection="column" marginTop="80px">
          {Object.entries(selectorContext.products).map(
            ([vendor, products]) => {
              return (
                <Flex flexDirection="column" marginBottom={5} key={vendor}>
                  <Styled.Title>{vendor}</Styled.Title>
                  <Flex
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-around"
                  >
                    {products.map((product) => (
                      <ProductCard
                        key={product?.id}
                        product={product}
                        selected={
                          !!selectorContext?.selectedProductMap?.[product.id]
                        }
                        onClick={() => selectorContext.toggleProduct(product)}
                      />
                    ))}
                  </Flex>
                </Flex>
              );
            }
          )}
        </Styled.ContentContainer>
      )}
    </>
  );
};

export default ProductSelector;
