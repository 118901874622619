import Pick from "@ramda/pick";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import { styled } from "@material-ui/core";

const labelMap = {
  style: "Style",
  abv: "ABV",
  ibu: "IBU",
  calories: "Calories",
  sugar: "Sugar",
  retail_sku: "Retail SKU",
  retail_price: "Retail Price",
  retail_availability: "Retail Availability",
  article_no: "Article #",
  format: "Format",
  licensee_price: "Licensee Price (excl. HST & Deposit)",
  licensee_availability: "Licensee Availability",
  retail_dc: "Retail DC",
};

function splitIntoColumns(metafields = {}) {
  const keysInOrder = Object.keys(labelMap);
  return [
    Object.entries(Pick(keysInOrder.slice(0, 5), metafields)),
    Object.entries(Pick(keysInOrder.slice(5, 9), metafields)),
    Object.entries(Pick(keysInOrder.slice(9), metafields)),
  ].filter((elem) => elem.length);
}

const ListItem = ({ product, ...rest }) => {
  const splitMetafields = splitIntoColumns(product.metafields);
  return (
    <View style={styles.container} wrap={false} {...rest}>
      <View style={styles.imageContainer}>
        <Image style={styles.productImage} src={product.smallImageSrc} />
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.productTitle}>{product.title}</Text>
          <Text style={styles.vendorName}>{product.vendor}</Text>
        </View>
        <View style={styles.descriptionContainer}>
          <Text style={styles.descriptionText}>{product.description}</Text>
        </View>
        <View style={styles.metaFeildContainer}>
          {splitMetafields.map((row, i) => (
            <View key={i} style={styles[`column${i}`]}>
              {row.map(([key, value]) => (
                <View key={key} style={styled.fieldLabelContnainer}>
                  <Text style={styles.key}>
                    {labelMap[key]}: {value}
                  </Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

export default ListItem;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginVertical: "5px",
  },
  imageContainer: {
    maxWidth: "100%",
    maxHeight: "120px",
    padding: "0px",
    margin: "0px",
    backgroundColor: "#f2f2f2",
  },
  productImage: {
    width: "auto",
    maxHeight: "120px",
    padding: "0px",
    margin: "0px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    paddingHorizontal: "6px",
    width: "90%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
  },
  productTitle: {
    padding: "0px",
    marginVertical: "3px",
    color: "black",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "10px",
  },
  vendorName: {
    padding: "0px",
    marginVertical: "3px",
    color: "grey",
    textTransform: "uppercase",
    fontWeight: "400",
    fontSize: "9px",
    marginLeft: "5px",
    alignSelf: "center",
  },
  descriptionContainer: {
    width: "90%",
  },
  descriptionText: {
    marginVertical: "2px",
    fontSize: "8px",
  },
  metaFeildContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    marginTop: "3px",
  },
  column0: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "15%",
    minWidth: "115px",
  },
  column1: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "15%",
    minWidth: "140px",
  },
  column2: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  fieldLabelContnainer: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid red",
  },
  key: {
    padding: "0px",
    margin: "1px 0px",
    fontWeight: "bold",
    fontSize: "9px",
  },
  value: {
    padding: "0px",
    margin: "1px 0px",
    marginLeft: "2px",
    fontSize: "10px",
  },
});
