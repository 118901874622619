import React from "react";
import MultiProductView from "./multiProductView";
import SingleProductView from "./singleProductView";

const PdfView = ({ title, salesRep, productsSplitByVendor }) => {
  const vendors = Object.keys(productsSplitByVendor || {});

  if (
    vendors?.length === 1 &&
    productsSplitByVendor?.[vendors[0]].length === 1
  ) {
    return (
      <SingleProductView
        title={title}
        salesRep={salesRep}
        productsSplitByVendor={productsSplitByVendor}
      />
    );
  }

  return (
    <MultiProductView
      title={title}
      salesRep={salesRep}
      productsSplitByVendor={productsSplitByVendor}
    />
  );
};

export default PdfView;
