import styled from "styled-components";
import { Flex } from "@rebass/grid";
import {
  Button as MuiButton,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const BREAK_POINT = 900;

export const InputsContainer = styled(Flex)`
  flex-direction: row;
  @media (max-width: ${BREAK_POINT}px) {
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
`;

export const ContentContainer = styled(Flex)`
  flex-direction: column;
  margin-top: 80px;
  @media (max-width: ${BREAK_POINT}px) {
    margin-top: 200px;
  }
`;

export const DesktopOnlyContainer = styled(Flex)`
  @media (max-width: ${BREAK_POINT}px) {
    display: none;
  }
`;

export const Dropdown = styled(Autocomplete)`
  && {
    min-width: 250px;
    margin: 0px 10px;
  }
`;

export const Input = styled(TextField)`
  && {
    min-width: 250px;
    @media (max-width: ${BREAK_POINT}px) {
      margin-top: 6px;
      margin-bottom: 12px;
    }
  }
`;

export const Header = styled(Flex)`
  && {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    background-color: white;
    box-shadow: 0 4px 6px -6px #222;
    min-height: 80px;
    align-items: center;
    @media (max-width: ${BREAK_POINT}px) {
      min-height: 200px;
    }
  }
`;

export const Loader = styled(LinearProgress)`
  && {
    margin-top: 81px;
  }
`;

export const Button = styled(MuiButton)`
  && {
    height: fit-content;
    color: white;
    background-color: #b68f31;
    align-self: center;
  }
`;

export const Title = styled.h1`
  text-align: center;
  text-transform: uppercase;
`;
