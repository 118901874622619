import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useQuery } from "@apollo/react-hooks";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import salesReps from "../../salesReps";
import * as storeQueries from "./queries";

const createProductLists = ({ selectedProducts }) => {
  return selectedProducts.reduce((accum, product) => {
    if (accum[product.vendor]) {
      accum[product.vendor].push(product);
    } else {
      accum[product.vendor] = [product];
    }
    return accum;
  }, {});
};

export const SelectorContext = createContext();

export const SelectorProvider = ({ children }) => {
  const [selectedProductMap, setSelectedProductMap] = useState({});
  const [products, setProducts] = useState({}); // split by vendor

  const [title, setTitle] = useState("");
  const [salesRep, setSalesRep] = useState(null);

  const { search } = useLocation();
  const history = useHistory();
  const initialQueryParams = useRef(search);
  // const {
  //   loading: salesRepsLoading,
  //   error: salesRepsError,
  //   data: rawSalesReps,
  // } = useQuery(storeQueries.getSalesReps);

  // console.log(salesRepsLoading, salesRepsError, rawSalesReps);

  const {
    loading: productsLoading,
    error: productError,
    data: rawProducts,
  } = useQuery(storeQueries.getProducts);

  const toggleProduct = (product) => {
    setSelectedProductMap((prevProductMap) => {
      const newProductMap = { ...prevProductMap };
      if (prevProductMap[product.id]) {
        delete newProductMap[product.id];
      } else {
        newProductMap[product.id] = product;
      }
      return newProductMap;
    });
  };

  const selectAll = () => {
    const allSelected = Object.values(products).reduce((accum, _products) => {
      _products.forEach((_product) => (accum[_product.id] = _product));
      return accum;
    }, {});
    setSelectedProductMap(allSelected);
  };

  const selectedProductsSplitByVendor = useMemo(() => {
    return createProductLists({
      selectedProducts: Object.values(selectedProductMap || {}),
    });
  }, [selectedProductMap]);

  useEffect(() => {
    if (!productsLoading && rawProducts?.products?.edges) {
      const params = queryString.parse(initialQueryParams.current, {
        arrayFormat: "comma",
      });

      if (params?.selected && typeof params.selected === "string") {
        params.selected = [params.selected];
      }

      const selectedProductIdSet = new Set(params?.selected || []);
      const selectedProducts = {};

      if (params.repEmail) {
        setSalesRep(
          salesReps.find(({ email }) => email === params.repEmail) || null
        );
      }

      if (params.title) {
        setTitle(params.title);
      }

      const productsMapped = rawProducts.products.edges.reduce(
        (accum, { node }) => {
          console.log(node)
          const product = {
            id: node.id,
            description: node.description,
            imageSrc: node.images?.edges?.[0]?.node?.transformedSrc,
            smallImageSrc: node.images?.edges?.[0]?.node?.smallImage,
            title: node.title,
            vendor: node.vendor,
            productType: node.productType,
            metafields: node?.metafields?.reduce(
              (metafields, metafield) => {
                if (metafield) {
                  metafields[metafield.key] = metafield.value;
                }
                return metafields;
              },
              {}
            ),
          };
          if (accum[product.vendor]) {
            accum[product.vendor].push(product);
          } else {
            accum[product.vendor] = [product];
          }

          if (selectedProductIdSet.has(product.id)) {
            selectedProducts[product.id] = product;
          }

          return accum;
        },
        {}
      );
      setProducts((prevProducts) => {
        return Object.entries(productsMapped).reduce(
          (accum, [vendor, vendorProducts]) => {
            if (prevProducts[vendor]) {
              prevProducts[vendor].push(...vendorProducts);
            } else {
              accum[vendor] = vendorProducts;
            }
            return accum;
          },
          { ...prevProducts }
        );
      });

      setSelectedProductMap(selectedProducts);
    }
  }, [productsLoading, productError, rawProducts]);

  useEffect(() => {
    if (productsLoading || !Object.keys(products)) return;

    const queryValues = {
      selected: Object.keys(selectedProductMap),
    };

    if (salesRep?.email) {
      queryValues.repEmail = salesRep.email;
    }

    if (title) {
      queryValues.title = title;
    }
    const searchQuery = queryString.stringify(queryValues, {
      arrayFormat: "comma",
    });

    if (searchQuery) {
      const newRoute = `${history?.location?.pathname || "/"}?${searchQuery}`;
      history.replace(newRoute);
    } else {
      history.replace(`${history?.location?.pathname || "/"}`);
    }
  }, [productsLoading, selectedProductMap, history, products, salesRep, title]);

  return (
    <SelectorContext.Provider
      value={{
        toggleProduct,
        products,
        selectedProductMap,
        loading: productsLoading,
        error: productError,
        selectAll,
        selectedProductsSplitByVendor,
        title,
        setTitle,
        salesRep,
        setSalesRep,
        clearSelections: () => setSelectedProductMap({}),
      }}
    >
      {children}
    </SelectorContext.Provider>
  );
};
