import styled from "styled-components";
import { Button as MuiButton } from "@material-ui/core";
import { Flex } from "@rebass/grid";
import { PDFDownloadLink } from "@react-pdf/renderer";

export const DownloadLink = styled(PDFDownloadLink)`
  && {
    text-decoration: none;
    color: white;
    &:hover {
      color: #b68f31;
    }
  }
`;

export const Header = styled(Flex)`
  && {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    background-color: white;
    box-shadow: 0 4px 6px -6px #222;
    min-height: 80px;
    align-items: center;
  }
`;

export const BackButton = styled(MuiButton)`
  && {
    height: fit-content;
    color: white;
    background-color: #b68f31;
    &:hover {
      color: #b68f31;
      background-color: white;
    }
  }
`;

export const DownloadButton = styled(MuiButton)`
  && {
    margin-left: 10px;
    height: fit-content;
    color: white;
    background-color: #b68f31;
    text-decoration: none;
    &:hover {
      color: #b68f31;
      background-color: white;
    }
  }
`;

export const CopyButton = styled(MuiButton)`
  && {
    margin-left: 10px;
    height: fit-content;
    color: white;
    background-color: #b68f31;
    &:hover {
      color: #b68f31;
      background-color: white;
    }
  }
`;

export const VendorTitle = styled.h3`
  text-transform: uppercase;
  margin-bottom: 3px;
`;

export const Hr = styled.hr`
  width: 100%;
  color: #f2f2f2;
`;

export const Title = styled.h2`
  text-transform: uppercase;
`;

export const PageHeader = styled(Flex)`
  && {
    width: 100%;
    height: 180px;
    background-color: #faf9f6;
    flex-direction: column;
  }
`;

export const GoldStrip = styled.div`
  width: 100%;
  height: 10px;
  background-color: #b68f31;
`;

export const LogoContainers = styled(Flex)`
  width: 100%;
  height: 100%;
`;

export const Craft360Container = styled(Flex)`
  align-self: center;
`;
export const Craft360Image = styled.img`
  height: 115px;
  width: auto;
`;

export const RightContentContainer = styled(Flex)``;

export const LogosContainer = styled(Flex)`
  align-items: center;
  border-bottom: 1px solid lightgrey;
  @media only screen and (max-width: 780px) {
    display: none;
  }
`;

export const SalesRepInfo = styled(Flex)`
  @media only screen and (max-width: 780px) {
    display: none;
  }
`;

export const InfoText = styled.p`
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  margin-left: 6px;
  margin-right: 6px;
`;

export const LcLogoImage = styled.img`
  height: 75px;
  width: auto;
  margin: 10px;
`;

export const DejadoLogoImage = styled.img`
  height: 55px;
  width: auto;
  margin: 10px;
`;

export const LsdcLogoImage = styled.img`
  height: 75px;
  width: auto;
  margin: 10px;
`;

export const HighParkImage = styled.img`
  height: 35px;
  width: auto;
  margin: 10px;
`;
