import styled from "styled-components";
import { Flex } from "@rebass/grid";

export const Container = styled(Flex)`
  height: fit-content;
  max-width: 1000px;
  margin-bottom: 25px;
  page-break-after: always;
`;

export const Image = styled.img`
  width: auto;
  max-height: 200px;
  padding: 0;
  margin: 0;
`;

export const ImageContainer = styled(Flex)`
  && {
    max-width: 100%;
    max-height: 200px;
    padding: 0;
    margin: 0;
    background-color: #f2f2f2;
    @media only screen and (max-width: 832px) {
      justify-content: center;
    }
  }
`;

export const ProductName = styled.p`
  padding: 0px;
  margin: 5px 0px;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
`;

export const VendorName = styled.p`
  padding: 0px;
  margin: 5px 0px;
  margin-left: 10px;
  color: grey;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
`;

export const DescriptionText = styled.p`
  font-size: 13px;
  margin: 2px 0px;
`;

export const MetaField = styled.p`
  padding: 0px;
  margin: 1px 0px;
  font-size: 13px;
`;

export const MetaFieldKey = styled.span`
  font-weight: bold;
  font-size: 13px;
`;
