import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "@rebass/grid";

import bitly from '../../bitly';

// logos
import craft360Logo from "../../assets/craft360.png";
import lcLogo from "../../assets/lc-logo.png";
import dejadoLogo from "../../assets/dejado-logo.png";
import highparkLogo from "../../assets/highpark.png";
import lsdcLogo from "../../assets/lsdc-logo.png";

import { SelectorContext } from "../../contexts";
import ListItem from "./components/listItem";
import PDFDoc from "../../pdfDocs";

import * as Styled from "./styles";

const ProductList = () => {
  const history = useHistory();
  const selectorContext = useContext(SelectorContext);
  const [copied, setCopied] = useState(false);
  const numSelections = Object.keys(selectorContext.selectedProductMap).length;

  const fallbackCopy = async () => {
    // Maybe hit a rate limit on bitly
    console.log('Failed to compress URL. Check bitly rate limit.');
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      console.error(error, 'Fallback copy failed.')
    }
  } 

  const copyUrl = async () => {
    try {
      // bitly fails on localhost this converts it
      const url = window.location.href.startsWith('http://localhost:') ? window.location.href.replace('http://localhost:', 'http://127.0.0.1:') : window.location.href;
      const { link } = await bitly.short(url);
      await navigator.clipboard.writeText(link);

      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      fallbackCopy();
      console.error(error, "Failed to copy to clipbaord!");
    }
  };

  return (
    <>
      <Styled.Header
        paddingX={5}
        justifyContent="space-between"
        className="print-hide-print-elem"
      >
        <Flex>
          <Styled.BackButton onClick={() => history.push("/")}>
            Back
          </Styled.BackButton>
          {numSelections > 0 && (
            <Styled.DownloadButton onClick={() => {}}>
              <Styled.DownloadLink
                document={
                  <PDFDoc
                    title={selectorContext.title}
                    salesRep={selectorContext.salesRep}
                    productsSplitByVendor={
                      selectorContext.selectedProductsSplitByVendor
                    }
                  />
                }
                fileName="craft360-product-sheet.pdf"
                style={{ textDecoration: "none" }}
              >
                {({ blob, url, loading, error }) => {
                  return loading ? "Generating PDF ...." : "Download PDF";
                }}
              </Styled.DownloadLink>
            </Styled.DownloadButton>
          )}
          <Styled.CopyButton onClick={() => copyUrl()}>
            {copied ? "Copied!" : "Copy Link"}
          </Styled.CopyButton>
        </Flex>
      </Styled.Header>
      <Flex
        flexDirection="column"
        marginTop="80px"
        flex={1}
        alignSelf="center"
        alignItems="center"
        className="print-elem"
      >
        <Styled.PageHeader>
          <Styled.GoldStrip />
          <Styled.LogoContainers padding={2} justifyContent="space-between">
            <Styled.Craft360Container marginLeft={4}>
              <Styled.Craft360Image src={craft360Logo} />
            </Styled.Craft360Container>
            <Styled.RightContentContainer flexDirection="column">
              <Styled.LogosContainer>
                <Styled.LcLogoImage src={lcLogo} />
                <Styled.DejadoLogoImage src={dejadoLogo} />
                <Styled.LsdcLogoImage src={lsdcLogo} />
                <Styled.HighParkImage src={highparkLogo} />
              </Styled.LogosContainer>
              {selectorContext.salesRep && (
                <Styled.SalesRepInfo justifyContent="flex-end" marginRight={4}>
                  <Styled.InfoText>
                    {selectorContext.salesRep.name}
                  </Styled.InfoText>
                  <Styled.InfoText>|</Styled.InfoText>
                  <Styled.InfoText>
                    {selectorContext.salesRep.email}
                  </Styled.InfoText>
                  <Styled.InfoText>|</Styled.InfoText>
                  <Styled.InfoText>
                    {selectorContext.salesRep.phoneNumber}
                  </Styled.InfoText>
                </Styled.SalesRepInfo>
              )}
            </Styled.RightContentContainer>
          </Styled.LogoContainers>
        </Styled.PageHeader>
        {selectorContext.loading && <p>Fetching products ...</p>}
        {!numSelections && !selectorContext.loading && (
          <p>No products selected</p>
        )}
        <Flex marginX={4} flexDirection="column">
          {selectorContext.title && (
            <Styled.Title>{selectorContext.title}</Styled.Title>
          )}
          {Object.entries(
            selectorContext.selectedProductsSplitByVendor || {}
          ).map(([vendor, products]) => {
            return (
              <Flex flexDirection="column" key={vendor}>
                <Styled.VendorTitle>{vendor}</Styled.VendorTitle>
                <Styled.Hr />
                {products.map((product, i) => {
                  return (
                    <ListItem
                      key={product.id}
                      className="product-list-item"
                      product={product}
                    />
                  );
                })}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};

export default ProductList;
