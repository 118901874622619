import React from "react";

import * as Styled from "./styles";

const ProductSelector = ({ product = {}, selected, ...rest }) => {
  const { imageSrc, title, vendor } = product;
  return (
    <Styled.Container selected={selected} flexDirection="column" {...rest}>
      <Styled.ImageContainer justifyContent="center">
        <Styled.Image src={imageSrc} alt="Product Image" />
      </Styled.ImageContainer>
      <Styled.ContentContainer
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Styled.ProductLabel>{title}</Styled.ProductLabel>
        <Styled.VendorLabel>{vendor}</Styled.VendorLabel>
      </Styled.ContentContainer>
    </Styled.Container>
  );
};

export default ProductSelector;
