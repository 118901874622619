import styled from "styled-components";
import { Flex } from "@rebass/grid";

export const Container = styled.div`
  && {
    cursor: pointer;
    max-width: 340px;
    max-height: 420px;
    min-width: 157px;
    min-height: 157px;
    padding: 0;
    margin: 5px 0px;
    border: 2px solid transparent;
    ${({ selected }) => selected && "border: 2px solid #b68f31;"}
  }
`;

export const ImageContainer = styled(Flex)`
  && {
    max-width: 100%;
    max-height: 340px;
    background-color: #f2f2f2;
    padding: 0;
    margin: 0;
  }
`;

export const ContentContainer = styled(Flex)`
  padding: 0;
  margin: 0;
`;

export const Image = styled.img`
  width: 65%;
  height: auto;
  padding: 0;
  margin: 0;
`;

export const ProductLabel = styled.p`
  padding: 0px;
  margin: 5px 0px;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
`;

export const VendorLabel = styled.p`
  padding: 0px;
  margin: 5px 0px;
  color: grey;
  text-transform: uppercase;
  font-weight: 400;
`;
