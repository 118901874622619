import { Flex } from "@rebass/grid";
import Pick from "@ramda/pick";

import * as Styled from "./styles";

const labelMap = {
  style: "Style",
  abv: "ABV",
  ibu: "IBU",
  calories: "Calories",
  sugar: "Sugar",
  retail_sku: "Retail SKU",
  retail_price: "Retail Price",
  retail_availability: "Retail Availability",
  retail_dc: "Retail DC",
  format: "Format",
  licensee_price: "Licensee Price (excl. HST & Deposit)",
  licensee_availability: "Licensee Availability",
  article_no: "Article #",
};

// function splitEvery(arr, chunkSize = 3) {
//   const res = [];
//   for (let i = 0; i < arr.length; i += chunkSize) {
//     const chunk = arr.slice(i, i + chunkSize);
//     res.push(chunk);
//   }
//   return res;
// }

function splitIntoColumns(metafields = {}) {
  const keysInOrder = Object.keys(labelMap);
  return [
    Object.entries(Pick(keysInOrder.slice(0, 5), metafields)),
    Object.entries(Pick(keysInOrder.slice(5, 9), metafields)),
    Object.entries(Pick(keysInOrder.slice(9), metafields)),
  ].filter((elem) => elem.length);
}

const columnStyle = {
  0: {
    maxWidth: "25%",
    minWidth: "130px",
  },
  1: {
    maxWidth: "30%",
    minWidth: "210px",
  },
  2: {
    width: "100%",
  },
};

const ListItem = ({ product }) => {
  const splitMetafields = splitIntoColumns(product.metafields);
  return (
    <Styled.Container flexDirection={['column', 'column', 'row', 'row']} className="list-item">
      <Styled.ImageContainer>
        <Styled.Image src={product.smallImageSrc} alt={product.title} />
      </Styled.ImageContainer>
      <Flex flexDirection="column" paddingX={2}>
        <Flex flexDirection="row">
          <Styled.ProductName>{product.title}</Styled.ProductName>
          <Styled.VendorName>{product.vendor}</Styled.VendorName>
        </Flex>
        <Flex>
          <Styled.DescriptionText>{product.description}</Styled.DescriptionText>
        </Flex>
        <Flex flexDirection={['column', 'column', 'row', 'row']} marginTop={1} flex={1}>
          {splitMetafields.map((row, i) => (
            <Flex key={i} flexDirection="column" style={columnStyle[i]}>
              {row.map(([key, value]) => (
                <Flex key={key} margin="0px" padding="0px">
                  <Styled.MetaField>
                    <Styled.MetaFieldKey>{labelMap[key]}:</Styled.MetaFieldKey>{" "}
                    {value}
                  </Styled.MetaField>
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Styled.Container>
  );
};

export default ListItem;
