import { gql } from "@apollo/client";

// vendor
// productType

export const getProducts = gql`
  query {
    products(
      first: 50
      query: "product_type:Non-Alcoholic OR product_type:Whisky OR product_type:Tequila OR product_type:Vodka OR product_type:Gin OR product_type:Beer OR product_type:Cider OR product_type:RTD OR product_type:Wine OR product_type:Rum"
    ) {
      edges {
        cursor
        node {
          id
          title
          descriptionHtml
          description
          productType
          vendor
          metafields(identifiers: [
              { namespace: "info", key: "style" },
              { namespace: "info", key: "abv" },
              { namespace: "info", key: "ibu" },
              { namespace: "info", key: "calories" },
              { namespace: "info", key: "sugar" },
              { namespace: "info", key: "retail_sku" },
              { namespace: "info", key: "retail_price" },
              { namespace: "info", key: "retail_availability" },
              { namespace: "info", key: "retail_dc" },
              { namespace: "info", key: "format" },
              { namespace: "info", key: "licensee_price" },
              { namespace: "info", key: "licensee_availability" },
              { namespace: "info", key: "article_no" },
          ]) {
              namespace
              key
              value
          }
          images(first: 1) {
            edges {
              node {
                id
                transformedSrc
                altText
                originalSrc
                smallImage: transformedSrc(maxWidth: 200, crop: CENTER)
              }
            }
          }
        }
      }
    }
  }
`;

export const getSalesReps = gql`
  {
    customer(id: "gid://shopify/Customer/1931653124") {
      edges {
        node {
          id
          tags
          email
        }
      }
    }
  }
`;

// export const getSalesReps = gql`
//   {
//     customers(first: 10) {
//       edges {
//         node {
//           id
//           tags
//           email
//         }
//       }
//     }
//   }
// `;

/* META FIELDS FOR GQL AT FLAT LEVEL */
// article_no: metafield(namespace: "info", key: "article_no") {
//   value
//   type
// }
// retail_sku: metafield(namespace: "info", key: "retail_sku") {
//   value
//   type
// }
// retail_availability: metafield(
//   namespace: "info"
//   key: "retail_availability"
// ) {
//   value
//   type
// }
// retail_price: metafield(namespace: "info", key: "retail_price") {
//   value
//   type
// }
// licensee_availability: metafield(
//   namespace: "info"
//   key: "licensee_availability"
// ) {
//   value
//   type
// }
// licensee_price: metafield(namespace: "info", key: "licensee_price") {
//   value
//   type
// }
// sugar: metafield(namespace: "info", key: "sugar") {
//   value
//   type
// }
// abv: metafield(namespace: "info", key: "abv") {
//   value
//   type
// }
// style: metafield(namespace: "info", key: "style") {
//   value
//   type
// }
// format: metafield(namespace: "info", key: "format") {
//   value
//   type
// }
// retail_dc: metafield(namespace: "info", key: "retail_dc") {
//   value
//   type
// }
// calories: metafield(namespace: "info", key: "calories") {
//   value
//   type
// }
