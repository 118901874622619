import styled from "styled-components";
import { Button as MuiButton } from "@material-ui/core";
import { Flex } from "@rebass/grid";

export const Header = styled(Flex)`
  && {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    background-color: white;
    box-shadow: 0 4px 6px -6px #222;
    min-height: 80px;
    align-items: center;
  }
`;

export const BackButton = styled(MuiButton)`
  && {
    height: fit-content;
    color: white;
    background-color: #b68f31;
    &:hover {
      color: #b68f31;
      background-color: white;
    }
  }
`;
